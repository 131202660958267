@import './src/styles/variables';

.btnMenu {
  width: 100%;
  height: $header-main-height;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.showMenu {
  display: inline-flex;
  width: 16px;
  height: 16px;
  transition: all .3s;
  transform: rotate(0);

}

.hideMenuActive {
  transform: rotate(180deg);
  display: inline-flex;
}

.siderMenu {
  padding-top: $header-main-height;
  padding-bottom: $header-main-height;
}
