
.note {
  padding: 16px;
}

.headerNote {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.fieldInput {
  height: 44px;
}