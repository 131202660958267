
.table {
  padding-top: 24px;
}

.positiveSum {
  color: #63B690;
}

.cellId {
  display: flex;
  align-items: center;
  gap: 5px;
}

.questionButton {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
}

.date {
  font-size: 14px;
  font-weight: 500;
  color: #8A94A2;
  margin-bottom: 10px;
}

.goodTextInfo {
  font-size: 14px;
  font-weight: 400;
  color: #263B53;
}
