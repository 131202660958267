.cell {
  padding: 10px 16px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  position: relative;
  cursor: pointer;
}

.arrow {
  position: relative;

  &:before {
    width: 10px;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 13px;
    content: '';
    display: flex;
    background: #fff;
    transform: translateY(-50%) rotate(45deg);
  }
  &:after {
    width: 10px;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 6px;
    content: '';
    display: flex;
    background: #fff;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.waiting {
  background: #fab16e;
}

.new {
  background: #C0C4CA;
}

.progress {
  background: #faec6e;
}

.finish {
  background: #4c897a;
}

.usersList {
  width: 232px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 20;
  overflow-y: auto;
}

.usersItem {
  height: 30px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8e8e8e;
  text-align: left;

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
}