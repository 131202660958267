.cellId {
  display: flex;
  align-items: center;
  gap: 5px;
}

.questionButton, .writeGoodBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
}

.quantityBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.textarea {
  resize: none !important;
}
