
.materials {
  padding: 16px;
}

.headerMaterials {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.materialsName {
  width: 210px;
  height: 44px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;
  padding: 10px 12px;
  background: #F1F3F6;
  border: 1px solid #F1F3F6;
  border-radius: 8px;

  &:disabled {
    color: #263B53;
  }
}

.fieldInput {
  width: 100%;
  height: 44px;
  font-size: 16px;
  padding: 0 11px;
}