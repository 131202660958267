.modal {
  position: relative;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #F1F3F6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: lighten(#F1F3F6, 0.5);
    cursor: pointer;
  }
}

.orderName {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #263B53;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #263B53;
}

.fieldName {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263B53;
}

.field {
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;

  &:disabled {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #263B53;
  }
}

.dateCreated {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.inStockInfo {
  margin-bottom: 32px;
}

.inStockMaterial {
  font-size: 15px;
  font-weight: 500;
  color: #8A94A2;

  & > span.inStock {
    color: #63B690;
  }

  & > span.notInStock {
    color: #810808;
  }
}

.checkboxMaterial {
  font-size: 14px;
  font-weight: 400;
  color: rgba(38, 59, 83, 0.4);
}