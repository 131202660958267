@import '../../styles/variables';

.header {
  width: 100vw;
  height: $header-main-height;
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 64px 5px $left-menu-width-hide + 20;
  background: #fff;
  border-bottom: 1px solid #F1F3F6;
  display: flex;
  justify-content: flex-end;
  z-index: 500;
}

.user {
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 16px !important;
}

.btnWrap {
  display: flex;
  align-items: center;
}