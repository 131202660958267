
.rowHeaderGoodPanel {
  width: 100%;
  padding: 13px 16px;
  border-radius: 0;
}

.goodIcon {
  font-size: 26px;
  color: #263B53;
}

.productName {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #263B53;
}

.productNamePrice {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.productPrice {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #63B690;
}

.deleteGoodBtn {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
}