.fieldName {
  font-family: "Inter", sans-serif;
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #8A94A2;
  margin-bottom: 12px;
}

.field {
  width: 100%;
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
