.orderName {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #263B53;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: #EAF3FF;
  border: 1px solid #F1F3F6;
  border-radius: 10px;
}

.fieldName {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263B53;
}

.field {
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;

  &:disabled {
    //height: 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    resize: none;
  }
}

.dateCreated {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.card {
  background: #F1F3F6;
}

.modalWrap {
  padding: 0;
}

.materialFromStock {
  font-size: 15px;
  font-weight: 500;
  color: #8A94A2;
}