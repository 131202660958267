.cellId {
  display: flex;
  align-items: center;
  gap: 5px;
}

.questionButton, .writeGoodBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
}

.date {
  font-size: 14px;
  font-weight: 500;
  color: #8A94A2;
  margin-bottom: 10px;
}

.goodTextInfo {
  font-size: 14px;
  font-weight: 400;
  color: #263B53;
}

.quantityBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;
}

.textarea {
  resize: none !important;
}

.btnsWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clientText {
  font-size: 16px;
  color: #263B53;
}