
.inputCustomSize {
  width: 334px;
  height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.checkboxSize {
  height: 100%;
  margin-bottom: 0;
  color: #263B53;
}
