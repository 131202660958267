
.headerGoods {
  padding: 16px 32px 0;
}

.addGood {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #263B53;
}

.addGoodBtn {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addBtnIcon {
  color: #4D92EE;
  border-radius: 50%;
  box-shadow: 0 1.29412px 2.58824px rgba(77, 146, 238, 0.25);
  font-size: 22px;
}

.typeOrderSelect {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263B53;
}

.headerGood {
  width: 100%;
  background: #ffffff;
  border-radius: 0;
}

.collapse {
  padding: 0;
}

.panel {
  width: 100%;
  padding: 0;
  background: #F1F3F6;
  border: 1px solid #E6E9EE;
}

.formGoodContent {
  background: #fff;
}

.css-dev-only-do-not-override-ph9edi.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 32px;
}