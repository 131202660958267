.cellId {
  display: flex;
  align-items: center;
  gap: 5px;
}

.questionButton, .writeGoodBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
}

.quantityBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.textarea {
  resize: none !important;
  height: 134px !important;
  font-size: 16px;
  font-weight: 600;
  color: rgba(38, 59, 83, 0.88);
}
