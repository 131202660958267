.prefixIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  top: 50%;
  left: 12px;
  color: #8A94A2;
  transform: translateY(-50%);
  z-index: 2;
}

.ant-space-item {
  flex-grow: 1;
}