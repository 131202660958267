.pageHeader {
  width: 1360px;
  max-width: 100%;
  padding: 24px 0;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #263B53;
}