@import '../../styles/variables';

.section {
  width: 1232px + ($left-menu-width-hide * 2);
  padding: $header-main-height $left-menu-width-hide $padding-bottom-layout;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}