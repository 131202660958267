
.legalPerson {
  margin-bottom: 32px;
}
.marginBottom {
  margin-bottom: 8px;
}

.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
  margin-bottom: 12px;
  display: flex;
}

.field {
  width: 100%;
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.icon {
  width: 14px;
  height: 14px;
  color: #736CF3;
  opacity: 0.4;
}

.required {
  line-height: inherit;
}