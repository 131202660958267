@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import './variables';

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Inter', sans-serif;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    font-size: 16px;
  }
}

*, html, body {
  margin: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 10px; /* в основном для вертикальных полос прокрутки */
  height: 10px; /* в основном для горизонтальных полос прокрутки */
}

*::-webkit-scrollbar-thumb { /* плашка-бегунок */
  background: #868686;
}

*::-webkit-scrollbar-track { /* фон */
  background: #dcdcdc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type=text]::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #B2BAC4;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #fff url("../assets/icons/background.jpg") center/cover no-repeat;
}

#root {
  height: 100%;
}


.layout {
  min-height: 100vh;
  padding-left: $left-menu-width-hide;
  margin: 0 auto;
  background: transparent;
}

.ant-select-selection-search-input {
  font-size: 16px;
}

.ant-modal-wrap, .ant-modal-mask {
  z-index: 1040 !important;
}

.ant-menu-item-selected img,
.ant-menu-item:hover img, {
  filter: brightness(200%);
  -webkit-filter: brightness(200%);
  transition: filter 1s ease-in;
}