.orderHeader {
  position: relative;
  padding: 32px 32px 28px;
  margin: 0 !important;
}

.orderName {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #263B53;
}

.btnClose {
  width: 34px;
  height: 34px;
  display: block;
  font-size: 13px;
  background: #F1F3F6;
  border-radius: 50%;
  border: none;
  color: #8A94A2;
}

.typeOrder {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #263B53;

  & > span {
    font-weight: 500;
  }
}

.headerGoods {
  padding: 16px 32px 32px;
}

.addGood {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #263B53;
}

.addGoodBtn {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addBtnIcon {
  color: #4D92EE;
  border-radius: 50%;
  box-shadow: 0 1.29412px 2.58824px rgba(77, 146, 238, 0.25);
  font-size: 22px;
}

.collapse {
  padding: 0;
  border-radius: 0;
}

.panel {
  background: #F1F3F6;
}

.input {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;

  &:disabled {
    color: #263B53;
    border: none;
  }
}

.rowHeaderGoodPanel {
  width: 100%;
  padding: 13px 16px;
  border-radius: 0;
}

.goodIcon {
  font-size: 26px;
  color: #263B53;
}

.productName {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #263B53;
}

.deleteGoodBtn {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
}


.note {
  padding: 16px;
}

.headerNote {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.fieldInput {
  height: 44px;
}

.datesOrder {
  width: 612px;
  padding: 42px 32px 0;
  margin-bottom: 16px;
}

.buttons {
  width: 100%;
  background: #fff;
  justify-content: flex-end;
  padding: 20px 32px;
}

.btnSave {
  padding: 0 84px;
}
