.payment {
  padding: 32px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #263B53;
  margin-bottom: 24px;
}

.headerPayment {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
}

.fieldInput {
  width: 100%;
  height: 44px;

  &:disabled {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #263B53;
    border: none;
  }
}

.sum {
  &:disabled {
    color: #63B690;
  }
}

.prepaymentReceived {
  margin-bottom: 0;
  color: #263B53;
}

.checkboxWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}