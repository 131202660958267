
.datesOrder {
  width: 612px;
  padding: 0 32px;
  margin-bottom: 32px;
}

.headerText {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8A94A2;
  margin-bottom: 12px;
}