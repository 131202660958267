.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #263B53;
  margin-top: 0;
  margin-bottom: 32px;
}

.loginForm {
  width: 505px;
  height: 286px;
  padding: 20px;
}

.field {
  height: 48px;
  padding: 12px 16px;
}
.loginButton {
  width: 178px;
  height: 42px;
}

.error {
 color: red;
}