.headerGood {
  margin-bottom: 12px;
}

.goodNumber {
  font-family: 'Inter', sans-serif;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  background: #F1F3F6;
  border: 1px solid #F1F3F6;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;
}

.addFieldGood {
  display: flex;
  align-items: center;
  color: #4D92EE;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.summativeHead {
  &:disabled {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #63B690;
    border: none;
  }
}

.summative {
  &:disabled {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #263B53;
    border: none;
  }
}

.input {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #263B53;

  &:disabled {
    color: #263B53;
    border: none;
  }
}
